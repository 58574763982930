import { Lang } from 'atw-shared/utils'

export default {
  [Lang.EN]: {
    header: 'Random question',
  },
  [Lang.PL]: {
    header: 'Losowa sonda',
  },
}
