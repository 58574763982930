import { Lang } from 'atw-shared/utils'

export default {
  [Lang.EN]: {
    header: 'Settings',
    tabs: {
      appSettings: 'Application settings',
      personalDetails: 'Personal details',
      profilePicture: 'Profile picture',
      password: 'Password',
      payment: 'Payment',
      deactivation: 'Account deactivation',
    },
    appSettings: {
      fields: {
        theme: {
          label: 'Theme',
          options: {
            light: 'Light',
            dark: 'Dark',
          },
        },
        lang: {
          label: 'Language',
          options: {
            en: 'English',
            pl: 'Polish',
          },
        },
      },
    },
    avatar: {
      sliders: {
        zoom: 'Zoom',
        rotation: 'Rotation',
      },
      messages: {
        warning: 'Allowed formats: png, jpg, jpeg.',
      },
    },
    payment: {
      buttons: {
        makePayment: 'Make payment',
        seeTransactionDetails: 'Transaction details',
      },
    },
    deactivation: {
      info: 'You can use this form to completely deactivate your account. Deactivation means your profile and all your actions will be removed. After you submit, you will receive an email with deactivation link which you need to open in order to confirm your decision and finalize deactivation. You will be able to use the same email to register with us again, but your content will never be restored.',
      label: 'I would like to deactivate my account.',
    },
    buttons: {
      upload: 'Upload',
      open: 'Open ...',
      clear: 'Clear',
      reset: 'Reset',
    },
  },
  [Lang.PL]: {
    header: 'Ustawienia',
    tabs: {
      appSettings: 'Aplikacja',
      personalDetails: 'Dane personalne',
      profilePicture: 'Zdjęcie profilowe',
      password: 'Hasło',
      payment: 'Płatność',
      deactivation: 'Usunięcie konta',
    },
    appSettings: {
      fields: {
        theme: {
          label: 'Motyw',
          options: {
            light: 'Jasny',
            dark: 'Ciemny',
          },
        },
        lang: {
          label: 'Język',
          options: {
            en: 'Angielski',
            pl: 'Polski',
          },
        },
      },
    },
    userDetails: {
      fields: {
        sex: 'Płeć',
      },
    },
    avatar: {
      sliders: {
        zoom: 'Powiększenie',
        rotation: 'Rotacja',
      },
      messages: {
        warning: 'Dostępny format zdjęć: png, jpg, jpeg.',
      },
    },
    payment: {
      buttons: {
        makePayment: 'Zapłać',
        seeTransactionDetails: 'Informacje o transakcji',
      },
    },
    deactivation: {
      info: 'Ten formularz służy do całkowitej deaktywacji konta. Oznacza to, że twój profil, twoje sondy i historia głosowania zostaną usunięte. Po zaakceptowaniu formularza, dostaniesz email z linkiem deaktywacyjnym, który musisz otworzyć w celu ostatecznego potwierdzenia swojej decyzji. Po tej czynności wciąż będzie możliwe ponowne zarejestrowanie się przy użyciu tego samego adresu e-mail, ale twoje dane przepadną bezpowrotnie.',
      label: 'Potwierdzam chęć usunięcia mojego konta.',
    },
    buttons: {
      upload: 'Prześlij',
      open: 'Otwórz z dysku ...',
      clear: 'Wyczyść',
      reset: 'Resetuj',
    },
  },
}
