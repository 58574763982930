import { Lang, Filter } from 'atw-shared/utils'

export default {
  [Lang.EN]: {
    header: 'All questions',
    fields: {
      filter: {
        [Filter.All]: 'All questions',
        [Filter.Created]: 'My questions',
        [Filter.Answered]: 'Submitted',
        [Filter.NotAnswered]: 'Waiting',
        [Filter.Followed]: 'My follows',
        [Filter.Terminated]: 'Terminated',
      },
      categories: {
        label: 'Categories',
        placeholder: 'select ...',
      },
      search: {
        label: 'Text',
        placeholder: 'word_a word_b   or   "exact phrase"',
      },
    },
    list: {
      total: '{{ count }} in total',
    },
    buttons: {
      createdBy: 'Created by {{ username }}',
    },
    messages: [
      '- when filtering by categories, a question will need to be of at least one of selected categories to show up in the list',
      '- when searching by text, content of both - question title as well as its options - will be checked',
    ],
  },
  [Lang.PL]: {
    header: 'Sondy',
    fields: {
      filter: {
        [Filter.All]: 'Wszystkie',
        [Filter.Created]: 'Moje',
        [Filter.Answered]: 'Zagłosowałem',
        [Filter.NotAnswered]: 'Oczekujące',
        [Filter.Followed]: 'Obserwowane',
        [Filter.Terminated]: 'Zakończone',
      },
      categories: {
        label: 'Kategorie',
        placeholder: 'wybierz ...',
      },
      search: {
        label: 'Tekst',
        placeholder: 'słowo_a słowo_b   lub   "dokładna fraza"',
      },
    },
    list: {
      total: 'Znaleziono {{ count }}',
    },
    buttons: {
      createdBy: 'Utworzone przez {{ username }}',
    },
    messages: [
      '- filtrując przez kategorie, wyświetlone zostaną sondy, które należą do przynajmniej jednej z wybranych przez ciebie kategorii',
      '- tekst wyszukiwany jest w opisie sondy i w jej opcjach',
    ],
  },
}
