import { Lang, ReportReason } from 'atw-shared/utils'

export default {
  [Lang.EN]: {
    modals: {
      deleteQuestion: {
        header: 'Warning',
        description:
          'You are about to delete your question. Are you sure you want to do this ?',
      },
      terminateQuestion: {
        header: 'Terminating',
        description:
          'You are about to terminate your question. The question will stay visible, although no one will ever be able to answer it anymore. The action is irreversible. Are you sure you want to proceed ?',
      },
      reportQuestion: {
        header: 'Question reporting',
        description: 'What is the reason you are reporting this query ?',
        reasons: {
          [ReportReason.BadContent]: 'It has got an inappropriate content',
          [ReportReason.Duplicate]: 'It is a duplicate',
          [ReportReason.WrongLanguage]: 'It is written in a wrong language',
          [ReportReason.WrongCategory]: 'It has got a wrong category',
          [ReportReason.Other]: 'Other',
        },
      },
      noAccess: {
        header: 'Stop',
        description: {
          actionNotAllowedReasonOne: 'You need to be logged in.',
          actionNotAllowedReasonTwo: 'You need to confirm your e-mail.',
          actionNotAllowedReasonThree:
            'To activate all the features for good, please go to settings and make one time payment.',
        },
      },
      deleteAccount: {
        header: 'Deactivation',
        content: 'Are you sure you want to proceed with this action ?',
      },
    },
  },
  [Lang.PL]: {
    modals: {
      deleteQuestion: {
        header: 'Uwaga',
        description: 'Twoja sonda zostanie usunięta. Czy na pewno chcesz kontynuować ?',
      },
      terminateQuestion: {
        header: 'Uwaga',
        description:
          'Twoja sonda zostanie zamknięta. Pozostanie widoczna dla użytkowników, ale nie będzie już można udzielić na nią odpowiedzi. Ta zmiana jest nieodwracalna. Czy na pewno chcesz kontynuować ?',
      },
      reportQuestion: {
        header: 'Zgłaszanie problemu',
        description: 'Co jest nie tak z tą sondą ?',
        reasons: {
          [ReportReason.BadContent]: 'Zawiera niestosowną treść',
          [ReportReason.Duplicate]: 'To duplikat',
          [ReportReason.WrongLanguage]: 'Nie jest w języku angielskim',
          [ReportReason.WrongCategory]: 'Nie pasuje do wybranej kategorii',
          [ReportReason.Other]: 'Coś innego',
        },
      },
      noAccess: {
        header: 'Stop',
        description: {
          actionNotAllowedReasonOne: 'Musisz być zalogowany.',
          actionNotAllowedReasonTwo: 'Musisz potwierdzić swój email.',
          actionNotAllowedReasonThree:
            'W celu aktywacji pełnego konta, dokonaj jednorazowej płatności - link do formularza znajdziesz w ustawieniach w zakładce "Płatność"',
        },
      },
      deleteAccount: {
        header: 'Deaktywacja konta',
        content: 'Czy na pewno chcesz kontynuować ?',
      },
    },
  },
}
