import { Lang } from 'atw-shared/utils'

export default {
  [Lang.EN]: {
    header: 'Profile',
    badge: {
      userSinceInfo: 'Joined {{ timePassed }}',
      emailStatus: {
        confirmed: 'Email confirmed',
        notConfirmed: 'Email not confirmed',
      },
      fullAccount: 'Full account',
    },
    stats: {
      question_one: 'question created',
      question: 'questions created',
      answer_one: 'question answered',
      answer: 'questions answered',
    },
    buttons: {
      goToQuestions: 'Browse',
    },
    contextMenu: {
      changeAvatar: 'Change avatar',
      updatePersonalDetails: 'Update personal details',
    },
    activity: {
      title: 'Latest activity',
      createdAt: 'Created',
      answeredAt: 'Answered',
      terminatedAt: 'Terminated',
      reportedAt: 'Reported',
    },
  },
  [Lang.PL]: {
    header: 'Profil użytkownika',
    badge: {
      userSinceInfo: 'Dołączył(a) {{ timePassed }}',
      emailStatus: {
        confirmed: 'Email potwierdzony',
        notConfirmed: 'Email nie potwierdzony',
      },
      fullAccount: 'Pełne konto',
    },
    stats: {
      question_zero: 'sond',
      question_one: 'sonda',
      question: 'sond(y)',
      answer_zero: 'zagłosowań',
      answer_one: 'zagłosowanie',
      answer: 'zagłosowan(ia)',
    },
    buttons: {
      goToQuestions: 'Przeglądaj sondy',
    },
    contextMenu: {
      changeAvatar: 'Zmień zdjęcie profilowe',
      updatePersonalDetails: 'Aktualizuj dane personalne',
    },
    activity: {
      title: 'Ostatnia aktywność',
      createdAt: 'Dodano',
      answeredAt: 'Odpowiedziano',
      terminatedAt: 'Zakończono',
      reportedAt: 'Zgłoszono',
    },
  },
}
