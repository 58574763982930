import { Lang } from 'atw-shared/utils'

export default {
  [Lang.EN]: {
    stats: {
      user_zero: 'users registered',
      user_one: 'user registered',
      user: 'users registered',
      question_zero: 'questions created',
      question_one: 'question created',
      question: 'questions created',
      answer_zero: 'answers submitted',
      answer_one: 'answer submitted',
      answer: 'answers submitted',
    },
    latestSurveys: {
      button: 'Latest questions',
    },
    createNewQuestion: {
      button: 'Add new question',
    },
    goToAuthPage: {
      button: 'Log in / Register',
    },
    topUsers: {
      header: 'Most active users',
    },
    topQuestions: {
      header: 'Most popular questions',
    },
  },
  [Lang.PL]: {
    stats: {
      user_one: 'użytkownik',
      user: 'użytkowników',
      question_one: 'sonda',
      question: 'sond(y)',
      answer_one: 'zagłosowanie',
      answer: 'zagłosowan(ia)',
    },
    latestSurveys: {
      button: 'Najnowsze sondy',
    },
    createNewQuestion: {
      button: 'Utwórz sondę',
    },
    goToAuthPage: {
      button: 'Zaloguj / Zarejestruj',
    },
    topUsers: {
      header: 'Najaktywniejsi użytkownicy',
    },
    topQuestions: {
      header: 'Najpopularniejsze sondy',
    },
  },
}
