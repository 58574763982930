import { Lang } from 'atw-shared/utils'
import { APP_NAME, APP_NAME_SHORT } from 'client/utils'

export default {
  [Lang.EN]: {
    topBar: {
      appName: APP_NAME,
      appNameShort: APP_NAME_SHORT,
      browse: {
        label: 'Browse',
        items: {
          all: 'All questions',
          random: 'Randomly',
        },
      },
      create: 'Create',
      menu: {
        label: 'Account',
        items: {
          profile: 'Profile',
          settings: 'Settings',
          logout: 'Log out',
          login: 'Log in',
        },
      },
      loggedInAs: 'Logged in as',
      loggedOut: 'Logged out',
    },
    footer: {
      section1: {
        header: 'About',
        content:
          'Although the app has been officially released to production it is still being developed and new features are expected to be coming out constantly in the future.',
      },
      section2: {
        header: 'Contact',
        content:
          'Do not hesitate to get in touch in case you have some questions, comments or suggestions.',
      },
      section3: {
        header: 'Donation',
        content:
          "You don't know what to do with you money ? Donate using STRIPE PAYMENTS.",
      },
    },
    activationBox: {
      description: 'Please confirm your email address in order to activate your account.',
      buttons: {
        send: 'Send activation link',
        sent: 'Activation link sent',
      },
    },
  },
  [Lang.PL]: {
    topBar: {
      appName: APP_NAME,
      appNameShort: APP_NAME_SHORT,
      browse: {
        label: 'Przeglądaj',
        items: {
          all: 'Wszystkie sondy',
          random: 'Losowo',
        },
      },
      create: 'Dodaj',
      menu: {
        label: 'Konto',
        items: {
          profile: 'Profil',
          settings: 'Ustawienia',
          logout: 'Wyloguj',
          login: 'Zaloguj',
        },
      },
      loggedInAs: 'Zalogowano jako',
      loggedOut: 'Nie zalogowano',
    },
    footer: {
      section1: {
        header: 'Aplikacja',
        content:
          "Numbers Don't Lie ma ułatwiać pozyskiwanie opinii, gromadzić i udostępniać wiarygodne i niezmanipulowane statystyki. Będąc stałym użytkownikiem możesz przyczynić się do zbudowania autentycznego źródła informacji.",
      },
      section2: {
        header: 'Kontakt',
        content: 'Napisz do nas, jeśli masz jakieś pytania, uwagi lub sugestie.',
      },
      section3: {
        header: 'Darowizna',
        content:
          'Jeśli masz ochotę, możesz nas wspomóc przesyłając darowiznę przy użyciu serwisu STRIPE PAYMENTS.',
      },
    },
    activationBox: {
      description: 'Proszę potwierdź swój adres email w celu aktywacji konta.',
      buttons: {
        send: 'Wyślij link aktywacyjny',
        sent: 'Link aktywacyjny wysłany',
      },
    },
  },
}
