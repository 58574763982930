import { Lang } from 'atw-shared/utils'

export default {
  [Lang.EN]: {
    header: 'New question',
    headerPreview: 'New question (PREVIEW)',
    exampleBtn: 'Example data',
    fields: {
      type: {
        label: 'Type',
        options: {
          singleChoice: 'Single choice',
          singleChoiceYesOrNo: 'Single choice (YES or NO)',
          multiChoiceExact:
            'Multiple choice (exact number of required options to be selected)',
          multiChoiceRange: 'Multiple choice (range of required options to be selected)',
          rating: 'Rating (1-5 or 1-10)',
        },
      },
      yourQuestion: 'Question',
      answerNumber: 'Option {{ n }}',
      numOfOptions: 'Number of options to choose from',
      numOfSelectableOptions: 'Number of required options to be selected',
      categories: {
        label: 'Categories',
        placeholder: 'choose (max 3) ...',
      },
      canBeReanswered: 'Allow to resubmit',
    },
    messages: [
      '- please use English language',
      '- be careful when filling in this form, as you will not be able to edit this data later',
      '- you will see the preview of your new question before adding it',
    ],
  },
  [Lang.PL]: {
    header: 'Nowa sonda',
    headerPreview: 'Nowa sonda (PODGLĄD)',
    exampleBtn: 'Example data',
    fields: {
      type: {
        label: 'Rodzaj',
        options: {
          singleChoice: 'Pojedynczy wybór',
          singleChoiceYesOrNo: 'Single choice (YES or NO)',
          multiChoiceExact: 'Wielokrotny wybór (dokładna liczba odpowiedzi)',
          multiChoiceRange: 'Wielokrotny wybór (liczba odpowiedzi z przedziału)',
          rating: 'Ocena (1-5 or 1-10)',
        },
      },
      yourQuestion: 'Opis',
      answerNumber: 'Opcja {{ n }}',
      numOfOptions: 'Ilość opcji',
      numOfSelectableOptions: 'Ilość wymaganych odpowiedzi',
      categories: {
        label: 'Kategorie',
        placeholder: 'wybierz (max 3) ...',
      },
      canBeReanswered: 'Możliwa zmiana odpowiedzi',
    },
    messages: [
      '- używaj wyłącznie języka angielskiego',
      '- upewnij się, że formularz jest wypełniony poprawnie, ponieważ tych danych nie będziesz już mógł później edytować',
      '- możesz wyświetlić podgląd swojej sondy zanim ją dodasz',
    ],
  },
}
