import { Lang, ValidationErrorCode } from 'atw-shared/utils'

export default {
  [Lang.EN]: {
    [ValidationErrorCode.AlreadyTaken]: 'is already taken',
    [ValidationErrorCode.IncorrectPassword]: 'incorrect password',
    [ValidationErrorCode.NoSuchEmail]: 'no such email',
  },
  [Lang.PL]: {
    [ValidationErrorCode.AlreadyTaken]: 'jest w użyciu',
    [ValidationErrorCode.IncorrectPassword]: 'niepoprawne hasło',
    [ValidationErrorCode.NoSuchEmail]: 'adresu nie ma w bazie',
  },
}
