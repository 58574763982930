import { Lang } from 'atw-shared/utils'

export default {
  [Lang.EN]: {
    register: {
      header: 'Register',
      alreadyHaveAnAccount: 'Already have an account ?',
      login: 'Log in',
    },
    login: {
      header: 'Log in',
      dontHaveAnAccount: "Don't have an account ?",
      register: 'Register',
      forgotPassword: 'Forgot my password',
    },
    recover: {
      header: 'Password recovery',
    },
  },
  [Lang.PL]: {
    register: {
      header: 'Nowe konto',
      alreadyHaveAnAccount: 'Masz już konto ?',
      login: 'Zaloguj się',
    },
    login: {
      header: 'Logowanie',
      dontHaveAnAccount: 'Nie masz jeszcze konta ?',
      register: 'Zarejestruj się',
      forgotPassword: 'Nie pamiętam hasła',
    },
    recover: {
      header: 'Odzyskiwanie hasła',
    },
  },
}
