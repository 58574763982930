import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import { Lang } from 'atw-shared/utils'

import commonDict from 'client/dictionary/common'
import apiMsgsDict from 'client/dictionary/apiMsgs'
import apiValidationErrors from 'client/dictionary/apiValidationErrors'
import appDict from 'client/app/App/dict'
import rootPageDict from 'client/pages/RootPage/dict'
import authPageDict from 'client/pages/AuthPage/dict'
import homePageDict from 'client/pages/HomePage/dict'
import aboutPageDict from 'client/pages/AboutPage/dict'
import browseQuestionsPageDict from 'client/pages/BrowseQuestionsPage/dict'
import createQuestionPageDict from 'client/pages/CreateQuestionPage/dict'
import profilePageDict from 'client/pages/ProfilePage/dict'
import settingsPageDict from 'client/pages/SettingsPage/dict'
import randomQuestionPageDict from 'client/pages/RandomQuestionPage/dict'
import questionDict from 'client/components/Question/dict'

const resources = {
  [Lang.EN]: {
    translation: {
      common: commonDict[Lang.EN],
      apiMsgs: apiMsgsDict[Lang.EN],
      apiValidationErrors: apiValidationErrors[Lang.EN],
      app: appDict[Lang.EN],
      rootPage: rootPageDict[Lang.EN],
      authPage: authPageDict[Lang.EN],
      homePage: homePageDict[Lang.EN],
      aboutPage: aboutPageDict[Lang.EN],
      browseQuestionsPage: browseQuestionsPageDict[Lang.EN],
      createQuestionPage: createQuestionPageDict[Lang.EN],
      profilePage: profilePageDict[Lang.EN],
      settingsPage: settingsPageDict[Lang.EN],
      randomQuestionPage: randomQuestionPageDict[Lang.EN],
      question: questionDict[Lang.EN],
    },
  },
  [Lang.PL]: {
    translation: {
      common: commonDict[Lang.PL],
      apiMsgs: apiMsgsDict[Lang.PL],
      apiValidationErrors: apiValidationErrors[Lang.PL],
      app: appDict[Lang.PL],
      rootPage: rootPageDict[Lang.PL],
      authPage: authPageDict[Lang.PL],
      homePage: homePageDict[Lang.PL],
      aboutPage: aboutPageDict[Lang.PL],
      browseQuestionsPage: browseQuestionsPageDict[Lang.PL],
      createQuestionPage: createQuestionPageDict[Lang.PL],
      profilePage: profilePageDict[Lang.PL],
      settingsPage: settingsPageDict[Lang.PL],
      randomQuestionPage: randomQuestionPageDict[Lang.PL],
      question: questionDict[Lang.PL],
    },
  },
}

i18next.use(initReactI18next).init({
  resources,
  lng: Lang.EN,
  interpolation: {
    escapeValue: false,
  },
})

export default i18next
