import { AppMsgCode, Lang } from 'atw-shared/utils'

export default {
  [Lang.EN]: {
    [AppMsgCode.SomethingWentWrong]:
      'Something went wrong. Please refresh or try again later.',
    [AppMsgCode.AuthenticationFailed]: 'Authentication failed',
    [AppMsgCode.NoCredentialsProvided]: 'No credentials provided',
    [AppMsgCode.TokenExpired]: 'Your session expired. You need to log in again.',
    [AppMsgCode.NoSuchUser]: 'No such user',
    [AppMsgCode.NoSuchQuestion]: 'No such question',
    [AppMsgCode.EmailNotConfirmed]: 'Email not confirmed',
    [AppMsgCode.NotFullAccount]: 'Not a full account',
    [AppMsgCode.IllegalAction]: 'Illegal action performed',
    [AppMsgCode.ActivationLinkSent]:
      'Your activation link has been sent to you. Please check your mailbox.',
    [AppMsgCode.DeactivationLinkSent]:
      'Your deactivation link has been sent to you. Please check your mailbox.',
    [AppMsgCode.RecoveryLinkSent]:
      'Your password recovery link has been sent to you. Please check your mailbox.',
    [AppMsgCode.PasswordUpdated]: 'Password updated',
    [AppMsgCode.PasswordRecovered]:
      'Your password has been reset and sent to your mailbox. Please log in and update it as soon as possible.',
    [AppMsgCode.UserDetailsUpdated]: 'User details updated',
    [AppMsgCode.EmailAlreadyConfirmed]: 'Email already confirmed',
    [AppMsgCode.EmailConfirmed]:
      'Your email address has been confirmed. You can close this window.',
    [AppMsgCode.AccountRemoved]:
      'You account has been removed. You can close this window.',
    [AppMsgCode.PaymentAlreadyMade]: 'Payment already made',
    [AppMsgCode.CouldNotGetData]: 'Could not get data',
    [AppMsgCode.QuestionAlreadyFollowed]: 'Question already followed',
    [AppMsgCode.QuestionAlreadyUnfollowed]: 'Question already unfollowed',
    [AppMsgCode.QuestionDeleted]: 'Question deleted',
    [AppMsgCode.QuestionMustHaveBeenDeleted]: 'Question must have been deleted',
    [AppMsgCode.QuestionCreated]: 'Question created',
    [AppMsgCode.QuestionReported]: 'Question reported',
    [AppMsgCode.QuestionAlreadyReported]: 'Question already reported',
    [AppMsgCode.QuestionFollowed]: 'Question followed',
    [AppMsgCode.QuestionUnfollowed]: 'Question unfollowed',
    [AppMsgCode.QuestionTerminated]: 'Question terminated',
    [AppMsgCode.QuestionGotTerminated]: 'Question already terminated',
    [AppMsgCode.AvatarUpdated]: 'Uploaded !',
  },
  [Lang.PL]: {
    [AppMsgCode.SomethingWentWrong]:
      'Nie udało się wykonać żądania. Odśwież stronę lub spróbuj ponownie później.',
    [AppMsgCode.AuthenticationFailed]: 'Nie udało się zalogować',
    [AppMsgCode.NoCredentialsProvided]: 'Nie podano danych do logowania',
    [AppMsgCode.TokenExpired]: 'Twoja sesja wygasła. Musisz zalogować się ponownie.',
    [AppMsgCode.NoSuchUser]: 'Nie ma takiego użytkownika',
    [AppMsgCode.NoSuchQuestion]: 'Nie ma takiej sondy',
    [AppMsgCode.EmailNotConfirmed]: 'Twój email nie został potwierdzony',
    [AppMsgCode.NotFullAccount]: 'Nie posiadasz pełnego konta',
    [AppMsgCode.IllegalAction]: 'Niedozwolona akcja',
    [AppMsgCode.ActivationLinkSent]: 'Link aktywacyjny został wysłany. Sprawdź pocztę.',
    [AppMsgCode.DeactivationLinkSent]:
      'Link deaktywacyjny został wysłany. Sprawdź pocztę.',
    [AppMsgCode.RecoveryLinkSent]:
      'Link do zresetowania hasła został wysłany. Sprawdź pocztę.',
    [AppMsgCode.PasswordUpdated]: 'Zaaktualizowano hasło',
    [AppMsgCode.PasswordRecovered]:
      'Twoje hasło zostało zresetowane i przesłane do ciebie w mailu. Zaloguj się i zmień je na własne.',
    [AppMsgCode.UserDetailsUpdated]: 'Zaaktualizowano dane personalne',
    [AppMsgCode.EmailAlreadyConfirmed]: 'Twój email został już wcześniej potwierdzony',
    [AppMsgCode.EmailConfirmed]: 'Email potwierdzony. Możesz zamknąć to okno.',
    [AppMsgCode.AccountRemoved]: 'Twoje konto zostało usunięte. Możesz zamknąć to okno.',
    [AppMsgCode.PaymentAlreadyMade]: 'Dokonano już płatności',
    [AppMsgCode.CouldNotGetData]: 'Nie udało się pobrać danych',
    [AppMsgCode.QuestionAlreadyFollowed]: 'Już obserwujesz tą sondę',
    [AppMsgCode.QuestionAlreadyUnfollowed]: 'Nie obserwujesz tej sondy',
    [AppMsgCode.QuestionDeleted]: 'Sonda usunięta',
    [AppMsgCode.QuestionMustHaveBeenDeleted]: 'Sonda musiała zostać usunięta',
    [AppMsgCode.QuestionCreated]: 'Sonda dodana',
    [AppMsgCode.QuestionReported]: 'Zgłoszenie wysłane',
    [AppMsgCode.QuestionAlreadyReported]: 'Już zgłaszałeś tą sondę',
    [AppMsgCode.QuestionFollowed]: 'Sonda obserwowana',
    [AppMsgCode.QuestionUnfollowed]: 'Sonda nieobserwowana',
    [AppMsgCode.QuestionTerminated]: 'Sonda zakończona',
    [AppMsgCode.QuestionGotTerminated]: 'Sonda jest już zakończona',
    [AppMsgCode.AvatarUpdated]: 'Zaaktualizowano !',
  },
}
