import { Lang } from 'atw-shared/utils'

export default {
  [Lang.EN]: {
    header: 'Question',
    nav: {
      info: '{{ current }} of {{ total }}',
      info_page: '{{ current }} of {{ total }} (#{{pageNo}})',
    },
    contextMenu: {
      items: {
        report: 'Report',
        terminate: 'Terminate',
        delete: 'Delete',
        reanswer: 'Reanswer',
      },
      msgs: {
        linkCopied: 'Link copied to clipboard',
        open: 'Open',
      },
    },
    buttons: {
      follow: 'Follow',
      unfollow: 'Followed',
    },
    info: {
      creationTimePassed: 'Created {{ timePassed }}',
      terminationTimePassed: 'Terminated {{ timePassed }}',
      submissionTimePassed: 'Answered {{ timePassed }}',
      creationDateTime: '({{ dateTime }})',
      terminationDateTime: '({{ dateTime }})',
      submittedTimes_one: 'Answered by {{ count }} user',
      submittedTimes: 'Answered by {{ count }} users',
      liveFor: 'Live for {{ time }}',
    },
    content: {
      total: 'Total',
      average: 'Average',
    },
  },
  [Lang.PL]: {
    header: 'Sonda',
    nav: {
      info: '{{ current }} z {{ total }}',
      info_page: '{{ current }} z {{ total }} (#{{pageNo}})',
    },
    contextMenu: {
      items: {
        report: 'Zgłoś',
        terminate: 'Zamknij',
        delete: 'Usuń',
        reanswer: 'Zmień odpowiedź',
      },
      msgs: {
        linkCopied: 'Link skopiowany',
        open: 'Otwórz',
      },
    },
    buttons: {
      follow: 'Obserwuj',
      unfollow: 'Obserwujesz',
    },
    info: {
      creationTimePassed: 'Utworzono {{ timePassed }}',
      terminationTimePassed: 'Zakończono {{ timePassed }}',
      submissionTimePassed: 'Odpowiedziano {{ timePassed }}',
      creationDateTime: '({{ dateTime }})',
      terminationDateTime: '({{ dateTime }})',
      submittedTimes_one: 'Odpowiedział {{ count }} użytkownik',
      submittedTimes: 'Odpowiedziało {{ count }} użytkowników',
      liveFor: 'Dostępna przez {{ time }}',
    },
    content: {
      total: 'Razem',
      average: 'Średnia',
    },
  },
}
