import { Lang } from 'atw-shared/utils'

export default {
  [Lang.EN]: {
    buttons: {
      delete: 'Delete',
      close: 'Close',
      report: 'Report',
      proceed: 'Proceed',
      vote: 'Vote',
      cancel: 'Cancel',
      clear: 'Clear',
      reset: 'Reset',
      deactivate: 'Deactivate',
      save: 'Save',
      terminate: 'Terminate',
      share: 'Share',
      create: 'Create',
      more: 'More',
      search: 'Search',
      submit: 'Submit',
      back: 'Back',
      preview: 'Preview',
      edit: 'Edit',
      example: 'Example',
      refresh: 'Refresh',
      copyLink: 'Copy link',
      newTab: 'New tab',
      next: 'Next',
    },
    errors: {
      somethingWentWrong: 'Something went wrong !',
      requestTimeout: 'Request timeout !',
    },
    email: {
      label: 'Email',
      placeholder: 'username@domain.com',
    },
    username: {
      label: 'Username',
    },
    password: {
      password: 'Password',
      currentPassword: 'Current password',
      newPassword: 'New password',
    },
    date: {
      placeholder: 'DD-MM-YYYY',
      format: 'DD-MM-YYYY',
    },
    dateOfBirth: {
      label: 'Date of birth',
    },
    country: {
      label: 'Country of residence',
      placeholder: 'select ...',
    },
    sex: {
      label: 'Sex',
      female: 'Female',
      male: 'Male',
    },
    info: {
      nothingToDisplay: 'Empty',
      loading: 'Loading ...',
    },
    validation: {
      required: 'is required',
      minLength: 'must be {{ min }} characters at min',
      maxLength: 'must be {{ max }} characters at max',
      invalid: 'is invalid',
      invalidChars: 'contains invalid character(s)',
    },
    question: {
      selectExactly: 'Select exactly {{ count }} options',
      selectExactly_one: 'Select exactly {{ count }} option',
      selectBetween: 'Select between {{ min }} and {{ max }} options',
      canBeReanswered: 'you may reanswer it later',
      cannotBeReanswered: 'not allowed to reanswer',
    },
    list: {
      pagination: {
        placeholder: 'select ...',
        pageNo: 'Page #{{ pageNo }}',
      },
    },
  },
  [Lang.PL]: {
    buttons: {
      delete: 'Usuń',
      close: 'Wyjdź',
      report: 'Zgłoś',
      proceed: 'Kontynuuj',
      vote: 'Głosuj',
      cancel: 'Anuluj',
      clear: 'Wyczyść',
      reset: 'Resetuj',
      deactivate: 'Usuń',
      save: 'Zapisz',
      terminate: 'Zamknij',
      share: 'Udostępnij',
      create: 'Dodaj',
      more: 'Więcej',
      search: 'Wyszukaj',
      submit: 'Potwierdź',
      back: 'Wstecz',
      preview: 'Podgląd',
      edit: 'Edytuj',
      example: 'Przykład',
      refresh: 'Odśwież',
      copyLink: 'Skopiuj link',
      newTab: 'Nowe okno',
      next: 'Dalej',
    },
    errors: {
      somethingWentWrong: 'Coś poszło nie tak !',
      requestTimeout: 'Nie udało się uzyskać odpowiedzi !',
    },
    email: {
      label: 'Email',
      placeholder: 'nazwa@domena.pl',
    },
    username: {
      label: 'Nazwa użytkownika',
    },
    password: {
      password: 'Hasło',
      currentPassword: 'Aktualne hasło',
      newPassword: 'Nowe hasło',
    },
    date: {
      placeholder: 'DD-MM-RRRR',
      format: 'DD-MM-YYYY',
    },
    dateOfBirth: {
      label: 'Data urodzenia',
    },
    country: {
      label: 'Miejsce zamieszkania',
      placeholder: 'wybierz ...',
    },
    sex: {
      label: 'Płeć',
      female: 'Kobieta',
      male: 'Mężczyzna',
    },
    info: {
      nothingToDisplay: 'Pusto',
      loading: 'Ładowanie ...',
    },
    validation: {
      required: 'jest polem wymaganym',
      minLength: 'musi mieć przynajmniej {{ min }} znaków',
      maxLength: 'może mieć maksymalnie {{ max }} znaków',
      invalid: 'ma niepoprawną wartość',
      invalidChars: 'ma niedozwolone znaki',
    },
    question: {
      selectExactly: 'Zaznacz dokładnie {{ count }} odpowiedzi',
      selectExactly_one: 'Zaznacz dokładnie {{ count }} odpowiedź',
      selectBetween: 'Zaznacz od {{ min }} do {{ max }} odpowiedzi',
      canBeReanswered: 'możesz później zmienić swój wybór',
      cannotBeReanswered: 'nie będziesz już mógł zmienić swojego wyboru',
    },
    list: {
      pagination: {
        placeholder: 'wybierz ...',
        pageNo: 'Strona #{{ pageNo }}',
      },
    },
  },
}
