import { Lang } from 'atw-shared/utils'

export default {
  [Lang.EN]: {
    sections: [
      {
        icon: 'thumbsUp',
        header: 'What is "Numbers Don\'t Lie" ?',
        content: [
          "- it's a question-answer type of application",
          "- it's a platform for storing long-lived transparent questions asked and answered by the users",
        ],
      },
      {
        icon: 'thumbsDown',
        header: 'What is it not ?',
        content: [
          "- it's not a poll application for short-lived, private polls only visible to a closed group of users",
        ],
      },
      {
        icon: 'pointer',
        header: 'How to start using it ?',
        content: [
          '- browse through all questions and find something that interests you, the questions are grouped by status and there are search filters you can use to narrow results to more specific',
          '- answer some questions that you feel are related to you, by doing so you make the statistics more reliable, your contribution matters very much',
          '- create your own question, customize it in terms of a type, category, number of answers it has and more, add it to the database, so it is instantly visible to other users',
          "- share question link on the internet if you think it's worth checking out by wider audience, by doing so you make it more possible for the community to grow",
        ],
      },
      {
        icon: 'write',
        header: 'When creating new question ...',
        content: [
          '- use only English language',
          '- do not use offensive words',
          '- do not duplicate data, check for similar questions before creating new one',
        ],
      },
    ],
  },
  [Lang.PL]: {
    sections: [
      {
        icon: 'idea',
        header: 'Aplikacja',
        content: [
          "Numbers Don't Lie ma ułatwiać pozyskiwanie opinii, gromadzić i udostępniać wiarygodne i niezmanipulowane statystyki. Będąc stałym użytkownikiem możesz przyczynić się do zbudowania autentycznego źródła informacji.",
          'Dorzuć swoje trzy grosze.',
        ],
      },
      {
        icon: 'search',
        header: '1. Find questions',
        content: [
          'Browse through all questions grouped by status category and use search filters to filter out the ones you are not interested in and leave the ones you would like to see.',
        ],
      },
      {
        icon: 'checked',
        header: '2. Submit answers',
        content: [
          'Answer some questions that you feel are related to you. By doing so, you make the statistics more reliable. Your contribution matters very much.',
        ],
      },
      {
        icon: 'write',
        header: '3. Add your own questions',
        content: [
          'Customize your new question in terms of a type, category, number of answers it has and more. Add it to the database, so it is instantly visible to other users.',
        ],
      },
      {
        icon: 'share',
        header: '4. Share links',
        content: [
          "Post question link on the internet, if you think it's worth checking out by wider audience.",
        ],
      },
      {
        icon: 'cube',
        header: 'Zasady',
        content: [
          '- dodawaj sondy tylko w języku angielskim',
          '- nie używaj wulgarnych słów',
          '- nie duplikuj danych, poszukaj podobnych sond zanim dodasz własną',
          '- zgłaszaj sondy, które według ciebie łamią te zasady',
          '- najaktywniejsi użytkownicy są ustalani na podstawie liczby [dodanych sond + zagłosowań]',
        ],
      },
      {
        icon: 'payment',
        header: 'Płatność',
        content: [
          'W tej chwili serwis jest całkowicie darmowy, ale w przyszłości może się to zmienić.',
        ],
      },
      {
        icon: 'protect',
        header: 'Bezpieczeństwo',
        content: ['Token autoryzacyjny wygasa po 4 tygodniach.'],
      },
    ],
  },
}
